import React from 'react';
import Layout from 'modules/Layout';


async function action({ route }) {

  return {
    chunks   : ['shopify-multipass'],
    name     : route.name,
    component: (
      <Layout.components.LoaderLayout />
    ),
  };
}

export default action;
