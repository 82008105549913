import React from 'react';
import OidcService from 'services/OidcService';
import Layout from 'modules/Layout';
import ShopifyMultipass from 'modules/ShopifyMultipass';
import SessionStorage from 'libs/SessionStorage';


async function action({ route, pathname }) {
  let Component = null;

  if (process.env.BROWSER) {
    const user = await OidcService.getUser();
    if (user) {
      Component = <ShopifyMultipass.components.Choose />;
    } else {
      SessionStorage.setItem('returnPathname', pathname);
      await OidcService.login();
    }
  }
  return {
    chunks   : ['shopify-multipass'],
    name     : route.name,
    component: (
      <Layout.components.LogoLayout>
        { Component }
      </Layout.components.LogoLayout>
    ),
  };
}

export default action;
